import $ from 'jquery';

export default function commercialMap() {
  $('.map-image').css(
    'background-image',
    'url(/assets/images/commercial/10-min.jpg)'
  );

  $('.commercial-map li').click(function () {
    let $this = $(this);
    if ($this.hasClass('active')) {
      // $('.commercial-map li').removeClass('active');
    } else {
      $('.commercial-map li').removeClass('active');
      $this.addClass('active');
      var img = $this.data('img');
      $('.map-image').css('background-image', 'url(' + img + ')');
    }
  });

  $('.commercial .banner .btn').click(function () {
    $('html, body').animate(
      {
        scrollTop: $('.getintouch').offset().top,
      },
      1000
    );
  });
}
