import $ from 'jquery';

export default function canvasImage() {
    
    if($('body').hasClass('home')) {

        // cache output canvas element
        var c = document.getElementById('output'),
            ctx = c.getContext('2d'),
            $output = $('#output');

        function resize(){    
          $output.outerHeight($(window).height()-$output.offset().top- Math.abs($output.outerHeight(true) - $output.outerHeight()));
        }

        resize();

        $(window).on("resize", function(){                      
            resize();
        });

        // vid element
        var vid = document.getElementById('canvasVideo');

        ctx.globalCompositeOperation = "copy";       // will clear previous alpha

        function render() {
            ctx.drawImage(vid, 0, 0, vid.width, vid.height);              // draw current video frame

            var idata = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height),
                data = idata.data,
                len = data.length, i = 0;            // ..image data from canvas

            while(i < len) {
                var alpha = 255 - data[i];           // in this matte, white = fully transparent
                data[i] = data[i+1] = data[i+2] = 255; // clear matte to black
                data[i+3] = alpha;                   // set alpha
                i += 4;                              // next pixel
            }

            ctx.putImageData(idata, 0, 0);           // update canvas
            requestAnimationFrame(render);           // todo: add criteria to stop this
        }

        function init() {
            vid.play();
            
            ctx.fillStyle="#FFFFFF";
            ctx.fillRect(0,0, ctx.canvas.width, ctx.canvas.height);
            $('.background-image').css('opacity', '1');
            
            render();
            
            if($('body').hasClass('home')) {
              $('#page').css('margin-top', '0');  
            }
        }

        vid.addEventListener('canplaythrough', init);
        vid.play();
        vid.pause();
        if (vid.readyState === 4) {
            init();
        }
       
    }
}

