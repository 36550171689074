import $ from 'jquery';

// MAP
export default function googleMaps() {

  if($('body').hasClass('neighbourhood')) {

    $(".category-header").unbind('click.cat-head').on('click.cat-head', function() {
        $(".category-header").parent().removeClass("active");
        showCategory($(this).parent('h5').attr('id'), $(this).parent().addClass("active").attr("zoom"));

        $('.category-header-container').removeClass('active');
        $(this).parent('h5').parent().addClass('active');

        $('html, body').animate({scrollTop: $(".map-container").offset().top - $('nav').height()}, 1000);
    })

    $(".map-item").unbind('click.map-item').on('click.map-item',function() {
        $(".map-item").removeClass("active");
        getMarker($(this).text(), $(this).addClass("active").attr('zoom'));
    })

    var target,
        pcmarker,
        homemarker;

    function closeCategory(category){
      category = String(category).toLowerCase();
      // clean up
      infowindow.close();
      $("#" + category + "-container .map-items-container").slideUp();
    }

    function showCategory(category, zoom) {
        // console.log(category + " , " + zoom);
        //console.log(category);
        category = String(category).toLowerCase();
        // clean up
        infowindow.close();
        $(".map-items-container").not($("#" + category + "-container .map-items-container")).slideUp();
        // zoom // map.setZoom(Math.round(zoom));
        // open thing
        // $("#" + category + "-container .map-items-container").slideDown(250, function() {
        // target = $(".map-category").has('.category-header:contains("' + category + '")');
        target = $("#"+category+"-container");
        target.find($(".map-items-container")).slideDown().css('display', 'block');
        // hilite marker and set bounds   //  Make an array of the LatLng's of the markers you want to show
        // var LatLngList = new Array (new google.maps.LatLng (52.537,-2.061), new google.maps.LatLng (52.564,-2.017));
        var LatLngList = new Array(homemarker.getPosition());
        for (var i = 0; i < allMarkers.length; i++) {
            if (allMarkers[i].category == category) {
                allMarkers[i].setVisible(true);
                LatLngList.push(allMarkers[i].getPosition());
            } else {
                allMarkers[i].setVisible(false);
            }
        }
        //  Create a new viewpoint bound
        var bounds = new google.maps.LatLngBounds();
        //  Go through each...
        for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
            //  And increase the bounds to take this point
            bounds.extend(LatLngList[i]);
        }
        //  Fit these bounds to the map
        map.fitBounds(bounds);
    }

    function fitBounds() {
        // hilite marker and set bounds   //  Make an array of the LatLng's of the markers you want to show
        var LatLngList = new Array(homemarker.getPosition());
        for (var i = 0; i < allMarkers.length; i++) {
            LatLngList.push(allMarkers[i].getPosition());
        }
        //  Create a new viewpoint bound
        var bounds = new google.maps.LatLngBounds();
        //  Go through each...
        for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
            //  And increase the bounds to take this point
            bounds.extend(LatLngList[i]);
        }
        //  Fit these bounds to the map
        map.fitBounds(bounds);
    }

    function hideMarkers() {
        for (var i = 0; i < allMarkers.length; i++) {
            allMarkers[i].setVisible(false);
        }
    }

    function getMarker(name, zoom) {
        //console.log(name + " , " + zoom);
        for (var i = 0; i < allMarkers.length; i++) {
            var markername = allMarkers[i].name;
            markername = markername.replace("&amp;", "&");
            if (markername == name) {
                google.maps.event.trigger(allMarkers[i], 'click');
            }
        }
        if (String(zoom) != "undefined") {
            map.setZoom(Math.round(zoom));
        }
        // scroll if we're on mobile
        if ($(window).width() < 767) {
            $('body,html').animate({
                scrollTop: $("#map-canvas").offset().top - 10
            }, 650);
            return false;
        }
    }

    function optionallyEnableTouchUI(){
      // Check if the browser supports touch events
      if('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch) {
        forceTouchUI();
      }
    }

    function forceTouchUI(){
      // Trick the API into thinking we are using an android-like device to get the touch UI
      var newUserAgent = navigator.userAgent.toLowerCase();
      var osBlacklist = ['x11', 'macintosh', 'windows'];
      for(var i = 0; i < osBlacklist.length; ++i){
        newUserAgent = newUserAgent.replace(osBlacklist[i], 'android');
      }

      navigator.__defineGetter__('userAgent', function(){
        return newUserAgent;
      });
    }

    var map;
    var allMarkers = new Array;
    var mainmaker;
    var options = {
        alignBottom: true,
        boxClass: "ui-infobox",
        boxID: "tooltip",
        boxStyle: {
            width: '120px',
            padding: '5px 10px',
            background: 'white',
            color: '#484848',
            'text-align': 'center',
            'text-transform': 'uppercase'
        },
        closeBoxMargin: "",
        closeBoxURL: "",
        disableAutoPan: false,
        enableEventPropagation: false,
        infoBoxClearance: new google.maps.Size(0, 0),
        isHidden: false,
        maxWidth: 0,
        pane: "floatPane",
        pixelOffset: new google.maps.Size(-40, -20),
        zIndex: null
    };
    var infowindow = new InfoBox(options);

    function initMap() {
        // Create an array of styles.
        var styles = [
            {
                "featureType": "all",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": -30
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#353535"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#5c5d60"
                    },
                    {
                        "saturation": "0"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#505050"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#808080"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#7c7977"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "hue": "#0066ff"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#838588"
                    },
                    {
                        "weight": "8.79"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "weight": "1"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "weight": "1"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "5.61"
                    },
                    {
                        "color": "#838588"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "color": "#212121"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "weight": "3"
                    },
                    {
                        "visibility": "on"
                    },
                    {
                        "gamma": "2"
                    }
                ]
            },
            {
                "featureType": "transit.station.airport",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.station.bus",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.station.rail",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit.station.rail",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#444445"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            }
        ];

        // leave empty if hosting from route folder


        var isDraggable = $(document).width() > 480 ? true : false; 
        
        // Create a new StyledMapType object, passing it the array of styles,
        // as well as the name to be displayed on the map type control.
        var styledMap = new google.maps.StyledMapType(styles, {
            name: "Custom Map"
        });
        // Create a map object, and include the MapTypeId to add
        // to the map type control.
        var mapOptions = {
            zoom: 14,
            scrollwheel: false,
            draggable: isDraggable,
            center: new google.maps.LatLng(49.2508171,-123.1298509),
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
          }
        };

        // google api work on touchscreen
        optionallyEnableTouchUI();

        map = new google.maps.Map(document.getElementById('map-canvas'),
          mapOptions);
        //Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');
        google.maps.event.addListener(map, 'click', function() {
          infowindow.close();
        });
        // set main marker
        var orchidIcon = new google.maps.MarkerImage(window.webRoot + "/assets/images/neighbourhood/marker-grey.png", null, null, null, new google.maps.Size(140,75));
        homemarker = new google.maps.Marker({
          position: new google.maps.LatLng(49.2579764, -123.1852741),
          icon: orchidIcon,
          zIndex: 9999,
          map: map
        })
        // set precentation centre icons
        var pcIcon = new google.maps.MarkerImage(window.webRoot + "/assets/images/neighbourhood/marker-pc.png", null, null, null, new google.maps.Size(105,53));
        pcmarker = new google.maps.Marker({
          position: new google.maps.LatLng(49.2351167, -123.185625),
          icon: pcIcon,
          zIndex: 9999,
          map: map
        })

        $(".map-item-schools").each(function(markerNum) {
            var markercategory = $(this).attr('category');
            var name = $(this).html();
            var lat = $(this).attr('lat');
            var lng = $(this).attr('lng');
            var makerName = name.split('.').slice(1).join('.');

            var image = {
              url: window.webRoot + '/assets/images/neighbourhood/marker-'+(markerNum+1)+'.png',
              anchor: new google.maps.Point(-4, 12)
            };
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                name: name,
                category: markercategory,
                icon: image,
                map: map
            })
            allMarkers.push(marker);
            // box
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(name);
                infowindow.open(map, marker);
            });
        })

        $(".map-item-parks").each(function(markerNum) {
            var markercategory = $(this).attr('category');
            var name = $(this).html();
            var lat = $(this).attr('lat');
            var lng = $(this).attr('lng');
            var makerName = name.split('.').slice(1).join('.');
            var fake = $(this).attr('fake');

            if(fake == "yes"){
                var image = {
                  url: window.webRoot + '/assets/images/maps/fakie.png',
                  anchor: new google.maps.Point(-4, 12)
                };
            }else{
                var image = {
                  url: window.webRoot + '/assets/images/neighbourhood/marker-'+(markerNum+1)+'.png',
                  anchor: new google.maps.Point(-4, 12)
                };
            }

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                name: name,
                category: markercategory,
                icon: image,
                map: map
            })
            allMarkers.push(marker);
            // box
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(name);
                infowindow.open(map, marker);
            });
        })

        $(".map-item-retailers").each(function(markerNum) {
            var markercategory = $(this).attr('category');
            var name = $(this).html();
            var lat = $(this).attr('lat');
            var lng = $(this).attr('lng');
            var makerName = name.split('.').slice(1).join('.');

            var image = {
              url: window.webRoot + '/assets/images/neighbourhood/marker-'+(markerNum+1)+'.png',
              anchor: new google.maps.Point(-4, 12)
            };
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                name: name,
                category: markercategory,
                icon: image,
                map: map
            })
            allMarkers.push(marker);
            // box
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(name);
                infowindow.open(map, marker);
            });
        })

        $(".map-item-markets").each(function(markerNum) {
            var markercategory = $(this).attr('category');
            var name = $(this).html();
            var lat = $(this).attr('lat');
            var lng = $(this).attr('lng');
            var makerName = name.split('.').slice(1).join('.');

            var image = {
              url: window.webRoot + '/assets/images/neighbourhood/marker-'+(markerNum+1)+'.png',
              anchor: new google.maps.Point(-4, 12)
            };
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                name: name,
                category: markercategory,
                icon: image,
                map: map
            })
            allMarkers.push(marker);
            // box
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(name);
                infowindow.open(map, marker);
            });
        })

        $(".map-item-restaurants").each(function(markerNum) {
            var markercategory = $(this).attr('category');
            var name = $(this).html();
            var lat = $(this).attr('lat');
            var lng = $(this).attr('lng');

            var image = {
              url: window.webRoot + '/assets/images/neighbourhood/marker-'+(markerNum+1)+'.png',
              anchor: new google.maps.Point(-4, 12)
            };
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                name: name,
                category: markercategory,
                icon: image,
                map: map
            })
            allMarkers.push(marker);
            // box
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(name);
                infowindow.open(map, marker);
            });
        })

        // $(".map-item-transit").each(function(markerNum) {
        //     var markercategory = $(this).attr('category');
        //     var name = $(this).html();
        //     var lat = $(this).attr('lat');
        //     var lng = $(this).attr('lng');

        //     var image = {
        //       url: window.webRoot + '/assets/images/neighbourhood/marker.png',
        //       anchor: new google.maps.Point(-4, 12)
        //     };
        //     var marker = new google.maps.Marker({
        //         position: new google.maps.LatLng(lat, lng),
        //         name: name,
        //         category: markercategory,
        //         icon: image,
        //         map: map
        //     })
        //     allMarkers.push(marker);
        //     // box
        //     google.maps.event.addListener(marker, 'click', function() {
        //         infowindow.setContent(name);
        //         infowindow.open(map, marker);
        //     });
        // })


        // $('#work .category-header').trigger('click');
        showCategory('schools');
    }

    google.maps.event.addDomListener(window, 'load', initMap);

      // $(window).bind('gMapsLoaded', initMap);
      // window.loadGoogleMaps();
  }
} // End googleMaps