import $ from 'jquery';

export default function navigation() {
  var $header = $('header'),
      $contact = $('.contact'),
      $menuBtn = $('.menu-btn'),
      $root = $('html, body'),
      $nav = $('nav'),
      $navOptions = $('.nav-options a');


  $menuBtn.on('click', function(e) {
    e.preventDefault();
    $header.toggleClass('active');
  });

  $navOptions.on('click', function() {
    $header.removeClass('active');
  });

  $contact.click(function(){
    $root.animate({scrollTop:$(document).height()}, 'slow');
    return false;
  });

  $(window).scroll(function() {

    if ($(this).scrollTop() > 0)
     {
        $nav.addClass('scroll');
     } else {
        $nav.removeClass('scroll');
     }
   });

  // add active class to current page menu item
  var currentPage = location.pathname.split('/');
  var pageName = currentPage[currentPage.length - 2];

  // home page - index list item must have "home" id
  if (pageName == ""){
    $('.desktop-nav li #home').parent().addClass('active');
  } 
  else if (pageName == pageName){
    // every other page
    $('.desktop-nav li #'+pageName).parent().addClass('active');
  }

}