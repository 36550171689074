import $ from 'jquery';
import { $body } from '../main';

export default function bindUI() {

  /* If using Page.js, make sure you unbind and rebind events */
  $('button.menu')
    .unbind('click.show-menu')
    .on('click.show-menu', function() {
      // stuff
  });
}