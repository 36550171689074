/**
 * @file This file defines all client-side routing and transitioning logic.
 */
'use strict';


// Imported as an external dependency (you need to include it on the page)
import $ from 'jquery'; 
// import 'velocity';
// import 'velocity/velocity.ui';
// import 'fontspy';

/* Custom tasks - split out for organization */
import bindUI from './tasks/bindUI';
import googleMaps from './tasks/map';
import navigation from './tasks/nav';
import canvasImage from './tasks/canvas';
import animation from './tasks/animation';
import commercialMap from './tasks/commercialMap';

/* Page auto-routing rules and transitions */
import pm from './routes';
pm.startRouting();

/**
 * We attach these as separate functions so they can be called again
 * during AJAX loads 
 */
// Document has been loaded
$(documentLoaded);
// All media has been loaded
$(window).on('load', windowLoaded);

export var $body;

export function windowLoaded() {
    
}

// The reason we need to unbind and re-bind events is for AJAX loading.
// documentLoaded() will be called again after the page is imported with Page.js
export function documentLoaded() {
  $body = $('body');

  bindUI();
  googleMaps();
  navigation();
  animation();
  commercialMap();

  QueryLoader2(document.querySelector("body"), {
      barColor: "#5C5D5D",
      backgroundColor: "#FFFFFF",
      percentage: false,
      barHeight: 1,
      minimumTime: 500,
      fadeOutTime: 1000,
      onComplete: function() {
        canvasImage();
        if(screen.width > 1024) {
          setTimeout( function() {
            $('.home nav').addClass('active');
            $('.home .banner-container').addClass('active');
            $('.home .banner-container h1').addClass('active');
            $('.home .banner-container .content').addClass('active');
          }, 2200);
          if($body.hasClass('home')) {
            setTimeout( function() {
              $('.grey').addClass('active');
              $('.sixteenth img').addClass('active');
              $('.dunbar').addClass('active');
              $('.dunbar img').addClass('active'); 
            }, 2600);
          } else {
            setTimeout( function() {
              $('.grey').addClass('active');
              $('.sixteenth img').addClass('active');
              $('.dunbar').addClass('active');
              $('.dunbar img').addClass('active'); 
              $('.banner-container .content').addClass('active');
            }, 100);
          }
        } 
        if(screen.width < 1024) {
          setTimeout(function() {
            $('.banner-container .content').addClass('active');
            $('.home .banner-container h1').addClass('active');
          }, 100);
        }
      }
  });
  // canvasImage();
  // setTimeout(function() {
  //   canvasImage();
  //   animation();
  // }, 500);
  
  // fontSpy('neuzeit-grotesk', {
  //   glyphs: '\ue81a\ue82d\ue823',
  //   success: fontLoaded,
  //   failure: fontLoaded
  // });

  // function fontLoaded() {
  //   // Make sure font is loaded
  //   $(function() {
  //     $('.load-font').addClass('loaded');
  //   });
  // }

}