import $ from 'jquery';

export default function animation() {

  setTimeout( function() {
    $('.banner-header-animate').addClass('active');
  }, 300);

  var $body = $('body');
  var controller = new ScrollMagic.Controller();

  // Parallax of Flower

  if($body.hasClass('interiors') || $body.hasClass('neighbourhood') || $body.hasClass('floorplans') || $body.hasClass('team') || $body.hasClass('register')) {
     // build tween
    var tween = new TimelineMax ()
        .add([
          TweenMax.fromTo(".parallax-flower", 1, {autoAlpha: 0.5, transform: 'translate3d(0, 30px, 0)'}, {autoAlpha: 1, transform: 'translate3d(0, 200px, 0)', ease: Linear.easeNone})
        ]);

      // build scene
      var scene = new ScrollMagic.Scene({triggerElement: "#parallaxTrigger", duration: 1000})
              .setTween(tween)
              // .addIndicators() // add indicators (requires plugin)
              .addTo(controller);
  }

  if($body.hasClass('home')) {
      // build scenes
    new ScrollMagic.Scene({triggerElement: "#trigger1"})
        .setClassToggle("#animate1", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger2"})
        .setClassToggle(".animate2", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
  }
  if($body.hasClass('interiors')) {
    // var controller = new ScrollMagic.Controller();
      // build scenes
    new ScrollMagic.Scene({triggerElement: "#trigger1"})
        .setClassToggle("#animate1", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger2"})
        .setClassToggle("#animate2", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger3"})
        .setClassToggle(".animate3", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger4"})
        .setClassToggle(".animate4", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
  }

  if($body.hasClass('neighbourhood')) {
    // var controller = new ScrollMagic.Controller();
      // build scenes
    new ScrollMagic.Scene({triggerElement: "#trigger1"})
        .setClassToggle(".animate1", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger2"})
        .setClassToggle(".animate2", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
  }

  if($body.hasClass('team')) {
    // var controller = new ScrollMagic.Controller();
      // build scenes
    new ScrollMagic.Scene({triggerElement: "#trigger1"})
        .setClassToggle(".animate1", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger2"})
        .setClassToggle(".animate2", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger3"})
        .setClassToggle(".animate3", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    new ScrollMagic.Scene({triggerElement: "#trigger4"})
        .setClassToggle(".animate4", "active") // add class toggle
        // .addIndicators() // add indicators (requires plugin)
        .addTo(controller);
  }

}